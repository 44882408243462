<!-- (c) 才酝通 -->
<template>
    <div class="choice-item">
        <div v-if="previewImage" class="preview-image borderbox" @click="backDetail()">
            <img
                class="back-detail"
                src="../assets/img/back-s.png"
            />
            <div class="pic-item">
                <div
                class="pic"
                :style="{ 'background-image': 'url(' + thumb + ')' }"
                ></div>
            </div>
        </div>
        <div :class="isOver ? 'question-box-padding' : 'question-box '">
            <div class="item">
                <div class="info">
                    <div class="type">{{ num }}.{{ question.type_text }}（{{ question.score }}分）</div>
                </div>
                <div class="question-content">
                    <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.content"></div>
                </div>
            </div>
            <div class="choice-box">
                <div class="input-item" v-for="(item, index) of inputVal" :key="index">
                    <div class="name">填空{{ index + 1 }}（{{ questionAnswerRows[index].s }}分）：</div>
                    <div class="input-box">
                        <input
                            :disabled="isOver"
                            type="text"
                            class="input"
                            v-model="inputVal[index]"
                            placeholder="请输入你的答案"
                            @blur="change"
                        />
                        <template v-if="isOver">
                            <img
                                v-if="inputVal[index] === questionAnswerRows[index].a"
                                class="icon"
                                src="../assets/img/icon-right.png"
                            />
                            <img class="icon" src="../assets/img/icon-unright.png" v-else/>
                        </template>
                    </div>
                    <div class="answer" v-if="isOver">
                        答案：{{ questionAnswerRows[index].a }}
                    </div>
                </div>
            </div>
            <div class="line" v-if="isOver"></div>
            <template v-if="isOver">
                <div class="analysis-box">
                    <div class="status">
                        <div class="no-margin-top score" v-if="!wrongBook"><i></i>得分：{{ score }}</div>
                        <div class="level" v-if="question.level">
                            <span></span>难度：
                            <van-rate v-model="question.level" :size="20" color="#ffd21e" void-icon="star" void-color="#eee"/>
                        </div>
                        <div class="remark" v-if="question.remark">
                            <strong class="comment-w">解析：</strong>
                            <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.remark">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Rate } from 'vant-green';
Vue.use(Rate);
export default {
    props: [
        "question",
        "reply",
        "isCorrect",
        "isOver",
        "score",
        "wrongBook",
        "num",
    ],
    data() {
        return {
            inputVal: [],
            previewImage: false,
            thumb: null,
            remarkStatus: false,
        };
    },
    computed: {
        inputLength() {
            if (typeof this.question.input_length !== "undefined") {
                return this.question.input_length;
            }

            if (typeof this.question.answer !== "undefined" && this.question.answer) {
                return this.question.answer.split(",").length;
            }

            return 0;
        },
        questionAnswerRows() {
            if (typeof this.question === "undefined") {
                return [];
            }

            if (typeof this.question.answer_transform === "undefined") {
                return [];
            }

            if (this.question.answer_transform) {
                return this.question.answer_transform;
            }

            return [];
        },
    },
    mounted() {
        let replyContent = this.reply || "";
        let replyRows = replyContent;
        for (let i = 0; i < this.inputLength; i++) {
            if (typeof replyRows[i] !== "undefined") {
                this.inputVal.push(replyRows[i]);
            } else {
                this.inputVal.push(null);
            }
        }
    },
    methods: {
        change(e) {
            if (this.isOver) {
                return;
            }
            let val;
            if (e.target.value === "") {
                val = "";
            } else {
                val = this.inputVal;
            }
            this.$emit("update", this.question.id, val);
        },
        backDetail() {
            this.previewImage = false;
        },
        PreviewImage($event) {
            if ($event.target.src) {
                this.thumb = $event.target.src;
                this.previewImage = true;
            }
        },
        newPreviewImage(src) {
            this.thumb = src;
            this.previewImage = true;
        },
    }
}
</script>
<style lang="less" scoped>
    .question-box {
        width: 100%;
        float: left;
        position: relative;
        height: auto;
        .info {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            background-color: #fff;
            .type {
                height: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
        }
        .question-content {
            width: 100%;
            float: left;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 30px;
            padding: 20px 15px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 1px solid #f1f2f6;
            .content-render {
                width: 100%;
                float: left;
                height: auto;
            }
            .images-render {
                width: 100%;
                float: left;
                height: auto;
                margin-top: 10px;
                display: grid;
                grid-row-gap: 15px;
                grid-column-gap: 15px;
                grid-template-columns: repeat(3,minmax(0,1fr));
            }
        }
    }
    .question-box-padding{
        padding-bottom: 55px;
        width: 100%;
        float: left;
        position: relative;
        height: auto;
        .info {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            background-color: #fff;
            .type {
                height: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
        }
        .question-content {
            width: 100%;
            float: left;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 30px;
            padding: 20px 15px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 1px solid #f1f2f6;
            .content-render {
                width: 100%;
                float: left;
                height: auto;
            }
            .images-render {
                width: 100%;
                float: left;
                height: auto;
                margin-top: 10px;
                display: grid;
                grid-row-gap: 15px;
                grid-column-gap: 15px;
                grid-template-columns: repeat(3,minmax(0,1fr));
            }
        }
    }
    .choice-box {
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px 0px 15px;
        background-color: #fff;
        .input-item {
            width: 100%;
            float: left;
            height: auto;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 20px;
            .name {
                width: 100%;
                height: 15px;
                float: left;
                font-size: 15px;
                font-weight: 500;
                color: #333;
                line-height: 15px;
                margin-bottom: 20px;
            }
            .input-box {
                width: 100%;
                height: 44px;
                float: left;
                position: relative;
                input {
                    width: 100%;
                    height: 44px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 24px;
                    border-radius: 4px;
                    border: 1px solid #e5e5e5;
                    outline: none;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding-left: 10px;
                }
                .icon {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 15px;
                    top: 10px;
                }
            }
            .answer {
                width: 100%;
                height: 44px;
                float: left;
                margin-top: 10px;
                box-sizing: border-box;
                background: #f4fafe;
                border-radius: 4px;
                padding: 13px 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .line {
        width: 100%;
        float: left;
        height: 10px;
        background: #f1f2f6;
    }
    .no-margin-top {
        margin-top: 0px !important;
    }
    .preview-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    padding: 15px;
    background-color: #000000;
    display: flex;
    align-items: center;
    .back-detail {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 19px;
      height: 19px;
      cursor: pointer;
    }
    .pic-item {
      width: 100%;
      height: 100%;
      .pic {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }
  }
</style>
