<!-- (c) 才酝通 -->
<template>
    <div class="choice-item">
        <div v-if="previewImage" class="preview-image borderbox" @click="backDetail()">
            <img
                class="back-detail"
                src="../assets/img/back-s.png"
            />
            <div class="pic-item">
                <div
                class="pic"
                :style="{ 'background-image': 'url(' + thumb + ')' }"
                ></div>
            </div>
        </div>
        <div class="question-box">
            <div class="item">
                <div class="info">
                    <div class="type">{{ num }}.{{ question.type_text }}（{{ question.score }}分）</div>
                </div>
                <div class="question-content">
                    <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.content">
                    </div>
                </div>
            </div>

            <div class="choice-box">
                <template v-for="item in 10">
                    <div
                        class="choice-tap-item"
                        :key="item"
                        :class="{ active: 'option' + item === active }"
                        @click="change(item)"
                        v-if="question['option' + item]"
                        >
                        <template v-if="isOver">
                            <div
                            v-if="question.answer === 'option' + item"
                            class="answer-index"
                            >
                            <img class="icon" src="../assets/img/icon-right.png" />
                            </div>
                            <div v-else-if="'option' + item === active" class="answer-index">
                            <img class="icon" src="../assets/img/icon-unright.png" />
                            </div>
                            <div class="index" v-else>
                            {{ optionTypeTextMap["option" + item] }}
                            </div>
                            <div class="content">
                            <div
                                class="content-render"
                                @click="PreviewImage($event)"
                                v-html="question['option' + item]"
                                v-latex
                            ></div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="index">{{ optionTypeTextMap["option" + item] }}</div>
                            <div class="content">
                            <div
                                class="content-render"
                                @click="PreviewImage($event)"
                                v-html="question['option' + item]"
                                v-latex
                            ></div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div class="line" v-if="isOver"></div>
            <template v-if="isOver">
                <div class="analysis-box">
                    <div class="status">
                        <div class="answer">
                            <i></i>答案：{{ optionTypeTextMap[question.answer] }}
                        </div>
                        <div class="my-answer" v-if="!wrongBook && isCorrect !== 1">
                            <i></i>我的答案：{{ optionTypeTextMap[active] || "--" }}
                        </div>
                        <div class="score" v-if="!wrongBook">
                            <i></i>得分：{{ score }}
                        </div>
                        <div class="level" v-if="question.level">
                            <span></span>难度：
                            <van-rate v-model="question.level" :size="20" color="#ffd21e" void-icon="star" void-color="#d2d3d6"/>
                        </div>
                        <div class="remark" v-if="question.remark">
                            <strong class="comment-w">解析：</strong>
                            <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.remark">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Rate } from 'vant-green';
Vue.use(Rate);

export default {
    props: [
        "question",
        "reply",
        "isCorrect",
        "isOver",
        "score",
        "wrongBook",
        "num",
        "level"
    ],
    data() {
        return {
            loading: false,
            active: null,
            optionTypeTextMap: {
                option1: "A",
                option2: "B",
                option3: "C",
                option4: "D",
                option5: "E",
                option6: "F",
                option7: "G",
                option8: "H",
                option9: "I",
                option10: "J",
            },
            previewImage: false,
            thumb: null,
            remarkStatus: false,
        };
    },
    mounted() {
        this.active = this.reply;
        if (this.wrongBook) {
            this.remarkStatus = true;
        }
    },
    watch: {
        reply() {
            this.active = this.reply;
        },
    },
    methods: {
        backDetail() {
            this.previewImage = false;
        },
        change(index) {
            if (this.isOver) {
                return;
            }
            this.active = "option" + index;
            this.$emit("update", this.question.id, this.active);
        },
        PreviewImage($event) {
            if ($event.target.src) {
                $event.stopPropagation();
                this.thumb = $event.target.src;
                this.previewImage = true;
            }
        },
        newPreviewImage(src) {
            this.thumb = src;
            this.previewImage = true;
        },
    }
}
</script>
<style lang="less" scoped>
        .question-box {
            width: 100%;
            float: left;
            position: relative;
            padding-bottom: 55px;
            height: auto;
        .info {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            background-color: #fff;
            .type {
                height: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
        }
        .question-content {
            width: 100%;
            float: left;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 30px;
            padding: 20px 15px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 1px solid #f1f2f6;
            .content-render {
                width: 100%;
                float: left;
                height: auto;
            }
        }
    }
    .choice-box {
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px 0px 15px;
        background-color: #fff;
        .choice-tap-item {
            width: 100%;
            height: auto;
            float: left;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 30px;
            border-radius: 3px;
            border: none;
            &.active .index {
                background: #3ca7fa;
                border: 1px solid #3ca7fa;
                color: #fff;
            }
            .icon {
                width: 24px;
                height: 24px;
            }
            .index {
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: #333;
            }
            .content {
                flex: 1;
                color: #000;
                padding-left: 20px;
                line-height: 24px;
                font-size: 16px;
                font-weight: 400;
                color: #333;
                flex-wrap: wrap;
            }
        }
    }
    .line {
        width: 100%;
        float: left;
        height: 10px;
        background: #f1f2f6;
    }
    .preview-image {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 400;
        padding: 15px;
        background-color: #000000;
        display: flex;
        align-items: center;
        .back-detail {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 19px;
        height: 19px;
        cursor: pointer;
        }
        .pic-item {
        width: 100%;
        height: 100%;
        .pic {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
        }
    }
</style>
